<template>
    <div class="viewDetail">
        <!--        导航-->
        <div>
            <rxNavBar title="查看详情" ></rxNavBar>
<!--            <van-nav-bar fixed placeholder left-arrow title="维修列表" @click-left="returnLeft"></van-nav-bar>-->
        </div>
<!--        申请详情-->
        <div class="titleONe">申请详情</div>
<!--        申请详情主体内容-->
        <div class="applyDetail">
<!--            地址详情-->
            <div class="addressInfo">{{applyDetailList.roomDetailedAddress ? applyDetailList.roomDetailedAddress : '暂无'}}</div>
            <!--        分割线-->
            <div class="rule"></div>
            <!--       申请主体内容 -->
            <div class="divOne">
                <div class="leftDiv">
                    <p>
                        <span class="spanOne" span="2">报修人: </span>
                        <span class="spanTwo" span="1"> {{applyDetailList.applicationGlobaluserName ?applyDetailList.applicationGlobaluserName : '暂无'}}</span>
                    </p>
                    <p>
                        <span class="spanOne">订单来源: </span>
                        <span class="spanTwo"> {{applyDetailList.fromType ? applyDetailList.fromType : '暂无'}}</span>
                    </p>
                </div>
                <div class="rightDiv">
                    <p>
                        <span class="spanOne" span="1">联系方式: </span>
                        <span class="spanTwo" span="1"> {{applyDetailList.applicationGlobaluserMoblile ? applyDetailList.applicationGlobaluserMoblile : '暂无'}}</span>
                    </p>
                    <p>
                        <span class="spanOne">维修程度: </span>
                        <span class="spanThree"> {{applyDetailList.repairStatus ? applyDetailList.repairStatus : '暂无'}}</span>
                    </p>
                </div>
            </div>
            <div class="divTwo">
                <p>
                    <span class="spanOne">维修类型: </span>
                    <span class="spanTwo"> {{applyDetailList.dictionaryTitle ? applyDetailList.dictionaryTitle : '暂无'}}</span>
                </p>
                <p>
                    <span class="spanOne">预约时间: </span>
                    <span class="spanThree"> {{applyDetailList.appointmentTime ? applyDetailList.appointmentTime : '暂无'}}</span>
                </p>
                <p>
                    <span class="spanOne">报修描述: </span>
                    <span class="spanTwo">{{applyDetailList.afterRentDesprition ? applyDetailList.afterRentDesprition : '暂无'}}</span>
                </p>
            </div>
        </div>
<!--      申请详情的图片  -->
        <div class="applyDetailImgs" v-if="applyDetailImgList.length>0">
            <van-swipe :loop="false" :width="115" :show-indicators="false">
                <van-swipe-item   v-for=" (item,i) in applyDetailImgList" :key="item.id">
                    <div class="applyDetailImg">
                    <img  :src="item" @click="onChangeApply(i)"/>
                    </div>
                </van-swipe-item>
                <div class="custom-indicator">
                    {{applyDetailImgList.length}} 图
                </div>
            </van-swipe>
        </div>
        <van-image-preview v-model="showApply" :images="applyDetailImgList"  :start-position="start"></van-image-preview>

<!--       维修详情DIV标题 -->
        <div class="title">维修详情</div>
<!--        维修详情DIV-->
        <div class="applyDetail">
            <!--       申请主体内容 -->
            <div class="divOne">
                <div class="leftDiv">
                    <p>
                        <span class="spanOne" >维修人: </span>
                        <span class="spanTwo" >{{finishedDetail.userName ? finishedDetail.userName : '暂无'}}</span>
                    </p>
                    <p>
                        <span class="spanOne">预计费用: </span>
                        <span class="spanTwo">{{finishedDetail.expectFee ? finishedDetail.expectFee+'元' : '暂无'}}</span>
                    </p>
                </div>
                <div class="rightDiv">
                    <p>
                        <span class="spanOne" >联系方式: </span>
                        <span class="spanTwo" >{{finishedDetail.mobile ? finishedDetail.mobile : '暂无'}}</span>
                    </p>
                    <p>
                        <span class="spanOne">完成费用: </span>
                        <span class="spanThree">{{finishedDetail.finishFee ? finishedDetail.finishFee+'元' : '暂无'}}</span>
                    </p>
                </div>
            </div>
            <div class="divTwo">
                <p>
                    <span class="spanOne">预计上门时间: </span>
                    <span class="spanTwo">{{finishedDetail.lastDispatchTime ? finishedDetail.lastDispatchTime : '暂无'}}</span>
                </p>
                <p>
                    <span class="spanOne">完成时间: </span>
                    <span class="spanTwo">{{finishedDetail.finishTime ? finishedDetail.finishTime : '暂无'}}</span>
                </p>
                <p>
                    <span class="spanOne">处理反馈: </span>
                    <span class="spanTwo">{{finishedDetail.dealResult ? finishedDetail.dealResult : '暂无'}}</span>
                </p>
            </div>
            <div class="divTwo" v-if="finishedDetail.afterRentStatus=='8'">
                <p>
                    <span class="spanOne">取消人: </span>
                    <span class="spanTwo">{{finishedDetail.cancelPerson ? finishedDetail.cancelPerson : '暂无'}}</span>
                </p>
                <p>
                    <span class="spanOne">取消时间: </span>
                    <span class="spanTwo">{{finishedDetail.statusChangeTime ? finishedDetail.statusChangeTime : '暂无'}}</span>
                </p>
                <p>
                    <span class="spanOne">取消原因: </span>
                    <span class="spanTwo">{{finishedDetail.cancelReason ? finishedDetail.cancelReason : '暂无'}}</span>
                </p>
            </div>
        </div>
        <!--      维修详情的图片  -->
        <div class="applyDetailImgs"  v-if="maintainDetailImgList.length>0">
            <van-swipe :loop="false" :width="115" :show-indicators="false" >
                <van-swipe-item   v-for=" (item,i) in maintainDetailImgList" :key="item.id">
                    <div  class="applyDetailImg" >
                    <img :src="item" @click="onChangeMain(i)" />
                    </div>
                </van-swipe-item>
                <div class="custom-indicator">
                    {{maintainDetailImgList.length}} 图
                </div>
            </van-swipe>
        </div>
        <van-image-preview v-model="showMain" :images="maintainDetailImgList"  :start-position="start"	></van-image-preview>
        <!--       验收DIV标题 -->
        <div class="title"  v-if="afterrentHisotryList.length>0">验收记录</div>
        <!--   "     验收详情DIV-->
        <div v-if="afterrentHisotryList.length>0" >
        <div class="applyDetail" v-for="(item,i) in afterrentHisotryList" :key="i"  >
            <!--       验收主体内容 -->
            <div class="divOne">
                <div class="leftDiv">
                    <p>

                        <span class="spanOne" >验收结果: </span>
                        <span class="spanTwo" >{{item.dealResult ? item.dealResult : '暂无' }}</span>
                    </p>
                    <p >
                        <span class="spanOne" >验收人: </span>
                        <span class="spanTwo" >{{item.changeStaffName ? item.changeStaffName : '暂无' }}</span>

                    </p>
                </div>
                <div class="rightDiv" v-if="item.dealResultValue=='0'">
                    <p>
                        <span class="spanOne" >联系方式: </span>
                        <span class="spanTwo" >{{item.changeStaffMobile ? item.changeStaffMobile : '暂无' }}</span>
                    </p>
                    <p>
                        <span class="spanOne">最终费用: </span>
                        <span class="spanThree">{{item.fee ? item.fee+'元' : '暂无' }}</span>
                    </p>
                </div>
            </div>
            <div class="divTwo">
                <p v-if="item.dealResultValue=='0'">
                    <span class="spanOne">费用承担方: </span>
                    <span class="spanTwo">{{item.costBear ? item.costBear : '暂无' }}</span>
                </p>
                <p >
                    <span class="spanOne">验收时间: </span>
                    <span class="spanTwo">{{item.changeTime ? item.changeTime : '暂无' }}</span>
                </p>
                <div  class="evaluate" v-if="item.dealResultValue=='0'">
                    <span class="spanOne">服务人员评价: </span>
                    <div class="evaluateImg"   >
                        <van-rate v-model="item.evaluateStar"  :count="5" color="#FF5D3B" size="17px" readonly/>
                    </div>
                </div>
                <p v-if="item.dealResultValue=='0'">
                    <span class="spanOne">验收备注: </span>
                    <span class="spanTwo">{{item.remarks ? item.remarks : '暂无' }}</span>
                </p>
                <p v-if="item.dealResultValue=='1'">
                    <span class="spanOne">驳回原因: </span>
                    <span class="spanTwo">{{item.dictionaryTitle ? item.dictionaryTitle : '暂无' }}</span>
                </p>
            </div>
        </div>
        </div>
        <!--    验收DIV标题 -->
        <div class="title"  v-if="checkImgList.length>0">验收通过图片</div>
        <!--     验收记录的图片 通过 -->
        <div class="applyDetailImgs" v-if="checkImgList.length>0" >
            <van-swipe :loop="false" :width="115" :show-indicators="false">
                <van-swipe-item   v-for=" (item,i) in checkImgList" :key="item.id">
                    <div class="applyDetailImg">
                    <img  :src="item" @click="onChangeCheck(i)"/>
                    </div>
                </van-swipe-item>
                <div class="custom-indicator">
                    {{checkImgList.length}} 图
                </div>
            </van-swipe>
        </div>
        <van-image-preview v-model="showCheck" :images="checkImgList"  :start-position="start"></van-image-preview>

        <!--    验收DIV标题 -->
        <div class="title"  v-if="refusefinishImgs.length>0">验收驳回图片</div>
        <!--     验收记录的图片 通过 -->
        <div class="applyDetailImgs" v-if="refusefinishImgs.length>0" >
            <van-swipe :loop="false" :width="115" :show-indicators="false">
                <van-swipe-item   v-for=" (item,i) in refusefinishImgs" :key="item.id">
                    <div class="applyDetailImg">
                        <img  :src="item" @click="onChangeCheckReject(i)"/>
                    </div>
                </van-swipe-item>
                <div class="custom-indicator">
                    {{refusefinishImgs.length}} 图
                </div>
            </van-swipe>
        </div>
        <van-image-preview v-model="showCheckReject" :images="refusefinishImgs"  :start-position="startReject"></van-image-preview>
    </div>
</template>

<script>

    import { NavBar,Rate ,Swipe,SwipeItem,ImagePreview} from 'vant';
    import {globaluserId, responseUtil} from "../../../libs/rongxunUtil";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import {
        queryRepairApprovalHistory,
        queryRepairDetailCard,
        repairFinishDetail
    } from "../../../getData/getData";
    export default {
        components: {
            [NavBar .name]:NavBar,
            [Rate .name]:Rate,
            [Swipe .name]:Swipe,
            [SwipeItem .name]:SwipeItem,
            [ImagePreview.Component.name]:ImagePreview.Component,
            rxNavBar
        },
        data() {
            return {
                //==================申请详情初始化数据====================
                repair_id : '',
                //申请详情初始化数据List
                applyDetailList : {},
                //申请详情的图片列表
                applyDetailImgList : [],
                //预览图索引
                start:'',
                //预览图控制
                showApply:false,
                //==================维修详情初始化数据=================
                //维修详情初始化数据
                finishedDetail : {},
                //    维修详情的图片列表
                maintainDetailImgList : [],
                //预览图控制
                showMain : false,
                //======================验收记录====================
                //验收记录列表
                afterrentHisotryList : [
                    {}
                ],
                //服务人员评价

                //验收记录的IMG
                checkImgList : [],
                showCheck : false,
                refusefinishImgs : [],
                showCheckReject : false,
                startReject : ''
            }
        },
        created() {
            this.repair_id  = this.$route.query.repair_id
            this.queryRepairDetailCard()
            this.finishRepairHeadList()
            this.queryRepairApprovalHistory()
        },
        methods : {
            //申请详情初始化
            queryRepairDetailCard(){
                    let that = this
                    let initData = {}
                    initData.reid = that.repair_id
                queryRepairDetailCard(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,()=>{
                        that.applyDetailList = response.data.data.detail
                        for(let i=response.data.data.imgList.length-1;i>=0;i--){
                            that.applyDetailImgList[i]= response.data.data.imgList[i].path
                        }
                        that.applyDetailImgList.reverse()
                    })
                })

            },
            //维修完成详情
            finishRepairHeadList(){
                let that = this
                let initData = {}
                initData.user_id = globaluserId()
                initData.id = that.repair_id
                repairFinishDetail(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,()=>{
                       that.finishedDetail = response.data.data.data
                        //维修完成详情的图片
                        for(let a=0;a<response.data.data.imgList.length;a++){
                            that.maintainDetailImgList.push(response.data.data.imgList[a].path)
                        }
                    })
                })
            },
            //验收记录详情
            queryRepairApprovalHistory(){
                let that = this
                let initData = {}
                initData.id = that.repair_id
                queryRepairApprovalHistory(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,()=>{
                        that.afterrentHisotryList = response.data.data.afterrentHisotryList
                        for(let i=0;i<that.afterrentHisotryList.length;i++){
                            that.afterrentHisotryList[i].evaluateStar= parseInt(that.afterrentHisotryList[i].evaluateStar)
                        }
                        if(response.data.data.finishImgs.length>0){
                            //验收详情的图片
                            for(let a=0;a<response.data.data.finishImgs.length;a++){
                                that.checkImgList.push(response.data.data.finishImgs[a].path)
                            }
                        }
                         if(response.data.data.refusefinishImgs.length>0)   {
                            for(let a=0;a<response.data.data.refusefinishImgs.length;a++){
                                that.refusefinishImgs.push(response.data.data.refusefinishImgs[a].path)
                            }
                        }
                    })
                })
            },
            onChangeApply(i) {
                this.start=i
                this.showApply=true
            },
            onChangeMain(i) {
                this.start=i
                this.showMain=true
            },
            onChangeCheck(i){
                this.start=i
                this.showCheck=true
            },
            onChangeCheckReject(i){
                this.startReject=i
                this.showCheckReject=true
            },
        }

    }
</script>

<style lang="less" scoped >
    *{
        margin: 0;
        padding: 0;
    }
    /*标题CSS共通部分*/
    .titleONe,.title{
        font-size: 12px;
        width: 92%;
        color: #FF5D3B ;
        font-weight: bold;
    }
    /*申请详情的标题*/
    .titleONe{
        margin: 10px auto 5px;
    }
//其它两个公共标题
    .title{
        margin: 30px auto 5px;
    }
    /*  公共主体内容DIV  */
    .divOne{
        margin: 0 15px ;
        display: flex;
        font-size: 14px;
        line-height: 24px;
        overflow: hidden;
        .leftDiv{
            width: 45%;
        }
        .rightDiv{
            width: 55%;
            /*border:2px solid red;*/
        }
    }
    .divTwo{
        margin: 0 15px;
        font-size: 14px;
        line-height: 24px;
        word-break: break-word;
    }
    /*公共P标签*/
    p{
        .spanOne{
            font-weight: bold;
        }
        .spanTwo{
            color: #9a9a9a;
            font-size: 14px;
            line-height: 24px;
        }
        .spanThree{
            color: #FF5D3B;
            font-size: 13.5px;
        }
    }
    /*申请详情DIV*/
    .applyDetail{
        width: 92%;
        background-color: #FFFFFF ;
        margin: 0 auto 15px;
        border-radius: 8px;
        padding-bottom: 15px;
        padding-top: 15px;
    /*    地址DIV*/
        .addressInfo{
            font-size: 15px;
            font-weight: bold;
            margin:0px 15px 15px;
        }
    /*   分割线 */
        .rule{
            width: 100%;
            border: 0.5px solid #EFEFEF;
            margin-bottom: 15px;
        }
    }
/*    申请详情图片DIV*/
    .applyDetailImgs{
        position: relative;
        height: 108px;
        width: 92%;
        margin: 0 auto 15px;
        background-color: #FFFFFF;
        border-radius: 8px;
    /*图片*/
        .applyDetailImg{
            width: 100px;
            height: 75px;
            border-radius: 8px;
            margin: 15px 0px 18px 4px ;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #e1eaec;
            overflow: hidden;
            img{
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }
    /*    轮播的显示图片数*/
        .custom-indicator{
            position: absolute;
            left: 15px;
            top: 20px;
            padding: 1px 7px;
            font-size: 12px;
            background: rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            color: white;
        }
    }
/*    服务人员评价DIV*/
    .evaluate{
        display: flex;
        align-items: center ;
        height: 24px;
        font-weight: bold;
        .evaluateImg{
            margin-top: 4px;
            margin-left: 10px
        }
    }
</style>
